exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-calculator-jsx": () => import("./../../../src/pages/calculator.jsx" /* webpackChunkName: "component---src-pages-calculator-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-perechen-dokumentov-jsx": () => import("./../../../src/pages/perechen-dokumentov.jsx" /* webpackChunkName: "component---src-pages-perechen-dokumentov-jsx" */),
  "component---src-pages-prices-jsx": () => import("./../../../src/pages/prices.jsx" /* webpackChunkName: "component---src-pages-prices-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-services-3-ndfl-jsx": () => import("./../../../src/pages/services/3ndfl.jsx" /* webpackChunkName: "component---src-pages-services-3-ndfl-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-services-otchet-o-dvizhenii-sredstv-v-inostrannih-bankah-jsx": () => import("./../../../src/pages/services/otchet-o-dvizhenii-sredstv-v-inostrannih-bankah.jsx" /* webpackChunkName: "component---src-pages-services-otchet-o-dvizhenii-sredstv-v-inostrannih-bankah-jsx" */),
  "component---src-pages-services-uvedomlenie-o-schetah-v-inostranih-bankah-jsx": () => import("./../../../src/pages/services/uvedomlenie-o-schetah-v-inostranih-bankah.jsx" /* webpackChunkName: "component---src-pages-services-uvedomlenie-o-schetah-v-inostranih-bankah-jsx" */),
  "component---src-pages-tariffs-jsx": () => import("./../../../src/pages/tariffs.jsx" /* webpackChunkName: "component---src-pages-tariffs-jsx" */)
}

